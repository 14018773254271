<mat-accordion [displayMode]="'flat'" [multi]="true">
  @for (menuItem of menu; track menuItem; let index = $index) {
    @if (menuItem.type === 'link') {
      <mat-expansion-panel hideToggle="true" expanded="true">
        <a
          [routerLink]="buildRoute([menuItem.route])" routerLinkActive="active">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}"/>
        </a>
      </mat-expansion-panel>
    }
    @if (menuItem.type === 'extLink') {
      <mat-expansion-panel hideToggle="true" expanded="true">
        <a [href]="menuItem.route">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}"/>
        </a>
      </mat-expansion-panel>
    }
    @if (menuItem.type === 'extTabLink') {
      <mat-expansion-panel hideToggle="true" expanded="true">
        <a [href]="menuItem.route" target="_blank">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{item: menuItem}"/>
        </a>
      </mat-expansion-panel>
    }
    @if (menuItem.type === 'sub') {
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <span>{{menuItem.name}}</span>
        </mat-expansion-panel-header>
        <app-sidemenu #submenu
                           [menu]="menuItem.children!"/>
      </mat-expansion-panel>
    }
  }
</mat-accordion>
<ng-template #linkTypeTpl let-item="item" let-level="level">
  @if (item.icon) {
  <mat-icon class="menu-icon">{{item.icon}}</mat-icon>
  }
  @if (item.label) {
  <span class="menu-label">{{item.label.value}}</span>
  } @else {
  <span class="menu-name">{{item.name | translate}}</span>
  }
  @if (item.badge) {
  <span class="menu-badge bg-{{item.badge.color}}">{{item.badge.value}}</span>
  }
  @if (item.type=='sub') {
  <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
  }
</ng-template>
