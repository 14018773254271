<mat-toolbar id="toolbar">
  <app-branding [showName]="showBrandName" [width]="brandWidth"/>
    @if (showToggle) {
      <button mat-icon-button (click)="toggleSidenav.emit()">
        <mat-icon>menu</mat-icon>
      </button>
    } @else {
      <app-topmenu />
      <a href="https://programisto.fr/offres-emploi" target="_blank">
        <button mat-flat-button class="primary-button m-y-8">
          Offres d'emploi 👀
        </button>
      </a>
    }
</mat-toolbar>
