@if (showHeader) {
  <div class="matero-sidebar-header">
    <mat-toolbar>
      <app-branding [showName]="true" />

      <span class="flex-fill"></span>

      @if (showToggle) {
        <mat-slide-toggle hideIcon [checked]="toggleChecked" (change)="toggleCollapsed.emit()" />
      } @else {
        <button mat-icon-button (click)="closeSidenav.emit()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-toolbar>
  </div>
}

<div class="matero-sidebar-main">
  <app-sidemenu/>
  <div class="row">
    <div class="col-12 p-y-32" style="text-align: center;">
      <a href="https://programisto.fr/offres-emploi" target="_blank">
        <button mat-flat-button class="primary-button m-y-8">
          Offres d'emploi 👀
        </button>
      </a>
    </div>
  </div>
</div>
