<div id="nouveau">
  <mat-stepper #stepper [selectedIndex]="stepperIndex" [linear]="true" (selectionChange)="onStepChange($event)" style="background-color: transparent !important;">
    <!-- DESCRIPTION GENERALE -->
    <mat-step [stepControl]="formGroupDescription">
      <ng-template matStepLabel>Présentation du projet</ng-template>
      <form [formGroup]="formGroupDescription" class="p-x-24 p-y-0">
        <div class="d-flex row w-full m-x-0">
          <div class="col-12 col-sm-6">
            <mat-form-field class="w-full m-b-24" appearance="outline">
              <mat-icon matPrefix>person</mat-icon>
              <input matInput placeholder="{{'dialogs.full_name' | translate}}*" formControlName="fullname" required>
              @if (fullname!.errors && fullname!.errors['required']) {
                <mat-error>
                    <span>{{'validations.required' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              } @else if (fullname!.invalid) {
                <mat-error>
                    <span>{{'validations.invalid_value' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field class="w-full m-b-24" appearance="outline">
              <mat-icon matPrefix>business</mat-icon>
              <input matInput placeholder="{{'dialogs.company' | translate}}*" formControlName="company" required>
              @if (company!.errors && company!.errors['required']) {
                <mat-error>
                    <span>{{'validations.required' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              } @else if (company!.invalid) {
                <mat-error>
                    <span>{{'validations.invalid_value' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex row w-full m-x-0">
          <div class="col-12 col-sm-6">
            <mat-form-field class="w-full m-b-24" appearance="outline">
              <mat-icon matPrefix>mail</mat-icon>
              <input type="email" matInput placeholder="{{'dialogs.email' | translate}}*" formControlName="email" required>
              @if (email!.errors && email!.errors['required']) {
                <mat-error>
                    <span>{{'validations.required' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              } @else if (email!.invalid) {
                <mat-error>
                    <span>{{'validations.invalid_value' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field class="w-full m-b-24" appearance="outline">
              <mat-icon matPrefix>smartphone</mat-icon>
              <mat-icon matSuffix style="align-items: flex-start"
                        istoTooltip [tooltipHorizontalPosition]="'end'"
                        [tooltipIcon]="'info'"
                        [tooltipContent]="'Exemple: 0X XX XX XX XX (Aucun espace)'"
              >info</mat-icon>
              <input type="tel" numbersOnly matInput placeholder="{{'dialogs.tele' | translate}}*" formControlName="tele" required>
              @if (tele!.errors && tele!.errors['required']) {
                <mat-error>
                    <span>{{'validations.required' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              } @else if (tele!.invalid) {
                <mat-error>
                    <span>{{'validations.invalid_value' | translate}}
                      <strong>*</strong>
                    </span>
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex col-12">
          <mat-form-field appearance="outline" class="w-full m-b-24">
            <mat-icon matSuffix style="align-items: flex-start"
                      istoTooltip [tooltipHorizontalPosition]="'end'"
                      [tooltipIcon]="'info'"
                      [tooltipContent]="'Exemple: Assurance, Centrale d\'appel, Restauration ...'"
            >info</mat-icon>
            <input matInput formControlName="coreBusiness" placeholder="Domaine d’activité*">
            @if (coreBusiness!.errors && coreBusiness!.errors['pattern']) {
              <mat-error>
                  <span>{{'validations.invalid_value' | translate }}
                    <strong>*</strong>
                  </span>
              </mat-error>
            } @else if (coreBusiness!.invalid) {
              <mat-error>
                  <span>{{'validations.required' | translate }}
                    <strong>*</strong>
                  </span>
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="d-flex col-12">
          <mat-form-field appearance="outline" class="w-full m-b-8">
            <mat-icon matSuffix style="align-items: flex-start"
                      istoTooltip [tooltipIcon]="'info'" [tooltipHorizontalPosition]="'end'"
                      [tooltipContent]="'Exemple: Gestion comptable, Inventaire de stock, ...'"
            >info</mat-icon>
            <textarea matInput formControlName="concept" required
                      placeholder="Besoin*"
                      style="min-height: 200px; max-height: 400px;"></textarea>
            @if (concept!.errors && concept!.errors['maxlength']) {
              <mat-error>
                  <span>{{'validations.max_length' | translate : {maxLength:2000} }}
                    <strong>*</strong>
                  </span>
              </mat-error>
            } @else if (concept!.errors && concept!.errors['minlength']) {
              <mat-error>
                  <span>{{'validations.min_length' | translate : {minLength:10} }}
                    <strong>*</strong>
                  </span>
              </mat-error>
            } @else if (concept!.invalid) {
              <mat-error>
                  <span>{{'validations.required' | translate}}
                    <strong>*</strong>
                  </span>
              </mat-error>
            }
          </mat-form-field>
        </div>
      </form>
      <app-pdf-loader (sendContent)="sendPdfContent($event)"/>
      <div class="row m-x-0 p-x-24 p-y-8">
        <div class="col-12 d-flex justify-content-end">
          <button class="next-step-button" matStepperNext
                  [disabled]="formGroupDescription.invalid"
          >
            Passez à l’étape de définition des scénarios
          </button>
          <button mat-icon-button class="secondary-icon-button" matStepperNext
                  [disabled]="formGroupDescription.invalid"
          >
            <mat-icon>arrow_right_alt</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!-- SCENARIOS UTILISATEURS -->
    <mat-step [stepControl]="formGroupScenarios">
      <ng-template matStepLabel>Spécifications détaillées</ng-template>
      <form [formGroup]="formGroupScenarios" class="p-24">
        <div formArrayName="useCases" class="col-12">
          @for (useCase of useCases.controls; track useCase) {
          <div [formGroupName]="$index" class="w-full">
              <mat-form-field appearance="outline" class="w-full">
                  <button matSuffix mat-icon-button aria-label="Supprime le scénario"
                    style="margin-top: -8px !important;"
                    [disabled]="useCases.length < 2"
                      (click)="removeUseCase($index)">
                      <mat-icon>delete</mat-icon>
                  </button>
                  <mat-icon matSuffix style="align-items: flex-start"
                      istoTooltip [tooltipHorizontalPosition]="'end'"
                      [tooltipIcon]="'info'"
                      [tooltipContent]="'Exemple: Consulte un inventaire ...'"
                      >info</mat-icon>
                  <input matInput #useCaseInput placeholder="Scénario utilisateur *"
                      formControlName="useCase"
                      (keyup)="inlineAutocomplete($event, useCaseInput.value, 'useCase' + $index)"
                      [matAutocomplete]="auto">
                      @if (getUseCaseError($index)['required']) {
                        <mat-error>
                          <span>{{'validations.required' | translate }}
                            <strong>*</strong>
                          </span>
                        </mat-error>
                      } @else if (getUseCaseError($index)['pattern']) {
                        <mat-error>
                          <span>{{'validations.invalid_value' | translate }}
                            <strong>*</strong>
                          </span>
                        </mat-error>
                      }
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event, $index)">
                        @for( suggestion of suggestions['useCase'+$index]; track suggestion) {
                          <mat-option [value]="suggestion" [textContent]="suggestion"></mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
            </div>
          }
        </div> <!-- use cases -->
      </form>
      <div class="d-flex w-full row justify-content-start m-x-0 p-x-24 p-y-8">
        <div class="col-12 col-md-3 m-b-16" style="flex-basis: content;">
          <button id="autocomplete_scenario_button" mat-flat-button class="secondary-button m-r-8"
                  (click)="autocomplete()"
                  [disabled]="waitingForCompletion || (formGroupDescription.invalid && formGroupScenarios.invalid)"
          >
            <mat-icon>{{ waitingForCompletion ? 'cached' : 'lightbulb_outline' }}</mat-icon>
            {{ waitingForCompletion ? 'Générateur...' : 'dialogs.suggest' | translate }}
          </button>
        </div>
        <div class="col-12 col-md-3 m-b-16" style="flex-basis: content;">
          <button mat-flat-button class="secondary-button m-r-8"
                  [disabled]="!canAddUseCase" (click)="addNewUseCase()"
          >
            <mat-icon>add</mat-icon>
            {{'dialogs.add' | translate}}
          </button>
        </div>
        <div class="col-12 col-md-3 m-b-16" style="flex-basis: content;">
          <button mat-flat-button class="secondary-button m-r-8"
                  [disabled]="useCases.length < 3" (click)="removeAllUseCases()"
          >
            <mat-icon>delete</mat-icon>
            {{'dialogs.clear' | translate}}
          </button>
        </div>
      </div>
      <div class="row m-x-0 p-x-24 p-y-8">
        <div class="col-12 d-flex justify-content-end">
          <button class="next-step-button" matStepperNext
                  [disabled]="formGroupDescription.invalid || formGroupScenarios.invalid"
          >
            Génération du devis
          </button>
          <button mat-icon-button matStepperNext class="secondary-icon-button"
                  [disabled]="formGroupDescription.invalid || formGroupScenarios.invalid"
          >
            <mat-icon>arrow_right_alt</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!-- PROPOSITION DE DEVIS -->
    <mat-step>
      <ng-template matStepLabel>Recevez votre estimation</ng-template>
      <app-proposition-devis [devis]="devis" [waitingForService]="waitingForService"></app-proposition-devis>
    </mat-step>
  </mat-stepper>
</div>
