<div class="matero-container-wrap"
  [class.matero-sidenav-collapsed]="options.sidenavCollapsed && options.navPos !== 'top'"
  [class.matero-navbar-side]="options.navPos === 'side'"
  [class.matero-navbar-top]="options.navPos === 'top'"
  [class.matero-header-above]="options.headerPos === 'above'"
  [class.matero-header-fixed]="options.headerPos === 'fixed'"
  [dir]="options.dir">

  <ng-progress />

  <!-- Header Above -->
  @if (options.showHeader && options.headerPos === 'above') {
  <app-header
  (toggleSidenav)="sidenav.toggle()"
  (toggleSidenavNotice)="sidenavNotice.toggle()"
  [showBranding]="true" />
  }

  <mat-sidenav-container class="matero-container" autosize autoFocus>
    <mat-sidenav #sidenav class="matero-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
      [fixedInViewport]="true"
      [fixedTopGap]="72"
      [fixedBottomGap]="0"
    >
      <app-sidebar
        [showToggle]="!isOver"
        [showUser]="options.showUserPanel"
        [showHeader]="options.headerPos !== 'above'"
        [toggleChecked]="options.sidenavCollapsed"
        (toggleCollapsed)="toggleCollapsed()"
        (closeSidenav)="sidenav.close()" />
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over">
      <app-sidebar-notice />
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content">
      <main class="matero-page-content">
        <app-title/>
        <router-outlet />
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<app-footer/>
<isto-tooltip/>
