<footer>
  <img id="pet" src="images/pet.png" [ngStyle]="petStyles"/>
  <div class="w-full" style="max-width: 1480px;">
    <div class="row">
      <div class="col-12 col-md-5 m-y-8">
        <div class="w-full">
          <app-branding [showName]="true" [width]="258" color="white"/>
          <p class="m-t-32" style="max-width: 420px;">Programisto met au service des moyens et grands groupes une équipe d’experts qualifiés et
            flexibles dans
            les domaines du conseil numérique.</p>
          <p>Ponton 5 Quai Lawton 33300 Bordeaux</p>
          <a href="https://programisto.fr/offres-emploi" target="_blank">
            <button mat-flat-button class="tertiary-button m-y-8" style="outline: solid 2px #F0CD65">
              Offres d'emploi 👀
            </button>
          </a>
        </div>
        <div class="row w-full m-y-24 align-items-center" style="max-width: 250px;">
          <div class="col-4">
            <img class="w-full partner" src="images/Partner-Google.svg"/>
          </div>
          <div class="col-4">
            <img class="w-full partner" src="images/Partner-French-Tech.jpg"/>
          </div>
          <div class="col-4">
            <img class="w-full partner" src="images/Partner-Platform-SH.jpg"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="row">
          <div class="col-12 col-sm-4 m-y-8">
            <h4>Programisto</h4>
            <ul>
              <li>
                <a target="_blank" href="https://programisto.fr/expertise">
                  <span>Expertise</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://programisto.fr/offres-emploi">
                  <span>Offres d'emploi </span>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://programisto.fr/a-propos">
                  <span>Qui sommes-nous ? </span>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://programisto.fr/#contact-pop-up">
                  <span>Contactez-nous </span>
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-sm-4 m-y-8">
            <h4>Filiales & partenaires</h4>
            <ul>
              <li>
                <a href="https://lahorde.tech/" target="_blank">
                  <img src="images/ico-La-Horde.png" alt="La Horde, l'école du Jeu Vidéo à Bordeaux" width="25"
                       height="25"/>
                  La Horde
                </a>
              </li>
              <li>
                <a href="https://ecoledeturing.fr/" target="_blank">
                  <img src="images/ico-Ecole-de-Turing.png"
                       alt="École de Turing, Data, IA, Cybersécurité & Génie Logiciel" width="25" height="25"/>
                  École de Turing
                </a>
              </li>
              <li>
                <a href="https://vortex.camp/" target="_blank">
                  <img src="images/ico-Vortex.png" alt="Formations professionnelles dans le domaine de la technologie"
                       width="25" height="25"/>
                  Vortex
                </a>
              </li>
              <li>
                <a href="https://www.brumisphere.com/" target="_blank">
                  <img src="images/ico-Brumisphere.png" alt="Agence Webmarketing et Produit" width="25" height="25"/>
                  Brumisphère
                </a>
              </li>
              <li>
                <a href="https://genly-consulting.com/" target="_blank">
                  <img src="images/ico-Genly.png" alt="Recrutement Data, IA, Cybersécurité" width="25" height="25"/>
                  Genly
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-sm-4 m-y-8">
            <h4>Publications</h4>
            <ul>
              <li>
                <a href="https://programisto.fr/publications/entrepreneuriat/" target="_blank">
                  <img src="images/ico-Entrepreneuriat.svg" width="24" height="24"/>
                  Entrepreneuriat
                </a>
              </li>
              <li>
                <a href="https://programisto.fr/publications/evenements/" target="_blank">
                  <img src="images/ico-Event.png" width="24" height="24"/>
                  Événements
                </a>
              </li>
              <li>
                <a href="https://programisto.fr/publications/innovation/" target="_blank">
                  <img src="images/ico-Innovation.svg" width="24" height="24"/>
                  Innovation
                </a>
              </li>
              <li>
                <a href="https://programisto.fr/publications/podcasts-tech/" target="_blank">
                  <img src="images/ico-Podcasts.svg" width="24" height="24"/>
                  Podcasts Tech
                </a>
              </li>
              <li>
                <a href="https://programisto.fr/publications/technologie-emergente/" target="_blank">
                  <img src="images/ico-Technologie.svg" width="24" height="24"/>
                  Technologie Émergente
                </a>
              </li>
              <li>
                <a href="https://programisto.fr/publications/transformation-digitale/" target="_blank">
                  <img src="images/ico-Transformation.svg" width="24" height="24"/>
                  Transformation Digitale
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="m-y-24" role="separator" aria-orientation="horizontal"/>
    <div class="row">
      <div class="col-12 m-y-8 rights">
        <p>Copyright 2024 © Programisto</p>
        <p>
          <app-linkedin-button [url]="'https://www.linkedin.com/company/programisto'"/>
        </p>
        <p>
          <a title="Mentions Légales" target="_blank" href="https://programisto.fr/mentions-legales">Mentions Légales</a>
          <span>&nbsp;|&nbsp;</span>
          <a title="Gérer la confidentialité" href="javascript:void(0);" onclick="openCookieSettings()">Gérer la confidentialité</a>
        </p>
      </div>
    </div>
  </div>
</footer>
