<div class="d-flex w-full row justify-content-start m-x-0 p-x-24 p-y-8">
  <div class="col-12">
    <mat-progress-bar mode="indeterminate" class="m-b-16"
                      [ngClass]="(loadingPdf | async) ? '' : 'invisible'"></mat-progress-bar>
    <input type="file" accept=".pdf" (change)="loadPdf($event)" #fileInput style="display: none;"/>
    <button mat-flat-button class="secondary-button m-r-8"
            (click)="fileInput.click()"
    >
      <mat-icon>upload</mat-icon>
      Choisir un fichier
    </button>
    <span class="align-content-center">
      @if ( file | async ) {
        {{ ( file | async ).name}}
      } @else {
        <strong>Optionnel:</strong>&nbsp;Transmettez-nous votre cahier des charges.
      }
    </span>
  </div>
</div>
